<template>
  <v-data-table
    :headers="computedHeaders"
    :items="data"
    :loading="tableLoading"
    class="elevation-1"
  >
    <template v-slot:item.actions="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" small class="mr-2" @click="edit(item)">
            mdi-pencil
          </v-icon>
        </template>
        <span>Editar</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" small @click="remove(item)"> mdi-delete </v-icon>
        </template>
        <span>Remover</span>
      </v-tooltip>
    </template>
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Ações</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>

        <v-dialog v-model="dialog" max-width="600px" @click:outside="close">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on"
              >Novo</v-btn
            >
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Ação</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <v-row>
                      <MetaSelect
                        v-model="Item.meta"
                        label="Meta"
                        :error-messages="errors['meta']"
                      />
                    </v-row>

                    <v-row>
                      <v-text-field
                        v-model="Item.descricao"
                        label="Descrição"
                        :error-messages="errors['descricao']"
                      ></v-text-field>
                    </v-row>
                    <v-row>
                      <v-text-field
                        v-model="Item.responsavel"
                        label="Responsável"
                        :error-messages="errors['responsavel']"
                      ></v-text-field>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-text-field
                          type="number"
                          v-model="Item.mes"
                          label="Mês"
                          :error-messages="errors['mes']"
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          type="number"
                          v-model="Item.ano"
                          label="Ano"
                          :error-messages="errors['ano']"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="darken-1" text @click="close">Cancelar</v-btn>
              <v-btn :loading="saving" color="darken-1" text @click="save"
                >Salvar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
  </v-data-table>
</template>

<script>
import { Acao } from "@/api/eqpdti";
import MetaSelect from "@/components/MetaSelect";
export default {
  name: "Acao",
  components: {
    MetaSelect,
  },
  data() {
    return {
      dialog: false,
      saving: false,
      tableLoading: false,
      errors: [],
      search: null,

      data: [],
      Item: {},
      headers: [
        {
          text: "ID",
          value: "acao_id",
          align: "center",
        },
        {
          text: "Descrição",
          value: "descricao",
          align: "left",
        },
        {
          text: "Meta",
          value: "meta_nome",
          align: "center",
        },
        {
          text: "Responsável",
          value: "responsavel",
          align: "center",
        },
        {
          text: "Ações",
          value: "actions",
          sortable: false,
          align: "end",
        },
      ],
    };
  },
  computed: {
    computedHeaders() {
      this.fetch();
      if (this.$state.role != "eqpdtiuo") {
        return [
          {
            text: "Unidade Organizacional",
            value: "uo_sigla",
            align: "center",
          },
        ].concat(this.headers);
      }
      return this.headers;
    },
  },

  methods: {
    remove(item) {
      if (window.confirm("Deseja realmente excluir?")) {
        this.tableLoading = true;
        Acao.remove(item["id"]).then(() => {
          this.tableLoading = false;
          this.fetch();
        });
      }
    },
    edit(item) {
      this.Item = Object.assign({}, item);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.Item = {};
      });
    },
    save() {
      this.saving = true;
      const _then = () => {
        this.saving = false;
        this.close();
        this.fetch();
      };
      const _catch = (error) => {
        this.saving = false;
        this.errors = error.response.data;
        if (this.errors["non_field_errors"]) {
          this.$emitter.emit("alert:error", this.errors["non_field_errors"][0]);
        } else {
          this.$emitter.emit("alert:error", this.errors);
        }
      };

      if (this.Item["id"]) {
        let id = this.Item["id"];

        Acao.update(id, this.Item).then(_then).catch(_catch);
      } else {
        this.Item = Object.assign(this.Item, {});
        Acao.post(this.Item).then(_then).catch(_catch);
      }
    },
    fetch() {
      this.tableLoading = true;
      Acao.list().then((response) => {
        this.tableLoading = false;
        this.data = response.data;
      });
    },
  },
  // created() {
  //   this.fetch();
  // },
};
</script>
